import React, { useState, useEffect, useRef } from "react";
import { Breadcrumb, Table, Image, Button } from "antd";
import { crossIcon, history, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import { routes } from "../../api/routes";
import Loader from "../../components/loader/loader";
import JoditEditor from "jodit-react";
import "./term.css";

const Term = () => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const [isloading, setIsLoading] = useState(false);
  const [updateApi, setUpdateApi] = useState(true);
  const [termID, setTermID] = useState("");

  const [content, setContent] = useState("");

  const handleEditorChange = (newContent) => {
    // console.log("Content changed:", newContent);
    setContent(newContent);
  };
  const config = {
    // Your Jodit configuration options here

    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    toolbarButtonSize: "large",
    toolbarSticky: false,
    minHeight: 300,
  };

  const getTerm = () => {
    let getRes = (res) => {
      console.log("get term", res);
      setContent(res.data[0].data);
      setTermID(res.data[0]?._id);
    };

    callApi("GET", routes.getTerm, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  const createTerm = () => {
    setUpdateApi(false);
    let body = {
      data: content,
    };
    let getRes = (res) => {
      setUpdateApi(true);
      console.log("create term", res);
    };

    callApi(
      "PATCH",
      `${routes.updateTerm}/${termID}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  const deleteTerm = () => {
    let getRes = (res) => {
      console.log("term delete", res);
    };

    callApi(
      "DELETE",
      `${routes.deleteTerm}/${termID}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getTerm();
  }, [updateApi]);

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
        <Breadcrumb.Item>Term and Condition</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1> {`Term and Condition`}</h1>
      </div>
      <div className="server-roles-tb-main-container-editor">
        <div style={{ marginTop: "4rem", width: "90rem" }}>
          <JoditEditor
            ref={editorRef}
            value={content} // Initial value
            config={config}
            onBlur={(newContent) => setContent(newContent)}
            // onChange={handleEditorChange}
            className="editor"
          />
        </div>
      </div>
      <div className="html-render">
        <div className="html-render-h1">
          <h1>Term and Condition Render</h1>
        </div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <div style={{ marginTop: "3rem" }}>
        <Button onClick={createTerm} type="primary">
          Create
        </Button>
      </div>
    </div>
  );
};

export default Term;
